.call-to-action-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    background-color: var(--purple);
    text-align: center;
}

.call-to-action-container h3 {
    color: var(--mint);
    padding-bottom: 1em;
}

.call-to-action-container p {
    color:#fff;
}

.call-to-action-container button {
    padding: .5em 1em;
    border: none;
    background-color: var(--mint);
    cursor: pointer;
    margin-top: 1em;
}

.call-to-action-container a {
    text-decoration: none;
    color: black;
}


@media only screen and (min-width: 768px) {

    .call-to-action-container {    
        padding: 5em;
    }

    .call-to-action-container h3 {
        font-size: 2rem;
    }
    
    .call-to-action-container p {
      line-height: 2rem;
    }
    
    .call-to-action-container button {

       font-size: 1.2rem;
       margin-top: 3em;
    }
    
}

@media only screen and (min-width: 1024px) {

    .call-to-action-container p {
        font-size: 1.2rem;
        line-height: 2.6rem;
      }

}