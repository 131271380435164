.ui-page-container {
    display: flex;
    flex-direction: column;
}

.ui-image {
    width: 100%;
    height: 550px;
    position: relative;
    background-color: darkblue;

}

.ui-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
    filter: grayscale(100%)
}

.ui-text {
    position: absolute;
    padding: 2em;
}

.ui-text h2 {
    font-size: 1.8rem;
    color: #fff;   
    margin-bottom: .4em; 
}

.ui-text h2 span {
    color: var(--mint)
}

.ui-text p {
    color: #fff;
}

.ui-buttons {
    margin: 2em 0em;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: first baseline;
    gap: 1em;
}

.ui-buttons button {
    padding: .5em 1em;
    border: none;
    border-radius: .2em;
    font-size: 1rem;
}

.ui-buttons button a {
    text-decoration: none;
}

.ui-buttons button.enrol {
    background-color: var(--mint);
}

.ui-status {
    color: #fff;
    letter-spacing: .16rem;
}

.ui-details {
    display: flex;
    flex-direction: column;
    background-color: var(--mint);
    padding: 5em 0em;
}

.ui-details>div {
    background-color: #fff;
    margin: 1em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    text-align: center;
    border-radius: .5em;
}

.ui-details>div p {
    color: grey;
    padding: .4em;
}

.ui-details>div h3 {
    color: var(--purple)
}

.ui-details img {
    width: 2em;;
}


.what-to-know {
    padding: 2.5em 2rem;
}

.what-to-know p {
    line-height: 1.8rem;
    color: rgb(71, 70, 70);
}

.what-to-know ul {
    line-height: 1.8rem;
    padding-left: 2rem;
    color: rgb(71, 70, 70);
}

.about-heading {
    padding-top: 2em;
    display: flex;
    align-items: center;
    gap: 1em;
}

.about-text  p {
    padding-top: 1em;
}

.what-to-learn-heading {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-top: 2em;
}

.what-to-learn-text  p {
    padding-top: 1em;
}

.prerequisites-heading {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-top: 2em;
}

.prerequisites-text  p {
    padding-top: 1em;
}
.course-outline-heading {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-top: 2em;
}

.class-schedule-text  p {
    padding-top: 1em;
}
.class-schedule-heading {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-top: 2em;
}

.course-outline-text  p {
    padding-top: 1em;
}

.course-outline-text span {
    font-weight: bold;
}


.what-to-know img {
    width: 2em;
}



@media only screen and (min-width: 768px) {
    .ui-text {
        padding: 3em 5em;
    }
    
    .ui-text h2 {
        font-size: 2.8rem;
    }
    
    .ui-text p {
      font-size: 1.2rem;
    }
    
    .ui-buttons {
        flex-direction: row;
    }

    .ui-details {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        padding: 3em;
    }
    .what-to-know {
        margin: 0em 2em;
    }
   
}

@media only screen and (min-width: 1024px) {
    .ui-text {
        padding: 4em 6em;
    }
    
    .ui-text h2 {
        font-size: 3rem;
    }
    
    .ui-text p {
      font-size: 1.5rem;
    }

    .ui-details {
        grid-template-columns: repeat(3, 2fr);
    }
    
    .what-to-know {
        margin: 0em 7em;
    }
}


