.expectation-container {
    display: flex;
    flex-direction: column;
}

.expectation-text h2 {
    padding: 2em;
    color: var(--purple)
}

.expectation-card {
    margin: 1em 2em;
    border: 1.3px solid var(--purple);
    padding: 1em;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.expectation-card h3 {
    color: var(--purple)
}

.expectation-card p {
    color: rgb(88, 88, 88);
    line-height: 1.8rem;
    margin-bottom: 1em;
}
.expectation-card img {
    width: 4em;
    padding: 1em 0em;
}


@media only screen and (min-width: 768px) {
    .expectation-text h2 {
        padding: 2em 3em;
        font-size: 2.3rem;
    }
    
    .expectation-card-holder {
        padding: 1em 4em;
        display: grid;
        grid-template-columns: repeat(2, 2fr);
    }
    

}

@media only screen and (min-width: 1024px) {
    .expectation-text h2 {
        padding: 2em 3em;
        font-size: 2.3rem;
    }
    
    .expectation-card-holder {
        padding: 1em 4em;
        display: grid;
        grid-template-columns: repeat(3, 2fr);
    }
    
}