.what-we-do-container {
    background-color: var(--purple);
    padding: 1.4em;
    display: flex;
    flex-direction: column;
}
.what-we-card-holder  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.what-we-do-text h2 {
    color: var(--mint);
    padding: 1.2em 1.2em 0em 1.2em;
}

.what-we-do-text p {
    color:#fff;
    padding: .5em 2em;
    line-height: 1.8rem;
}
.what-we-do-cards {
    background-color: #fff;
    margin-top: 2em;
    max-width: 560px;
    height: 100%;
    border-radius: 1em;
    box-shadow:  0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.card-image {
    max-width: 600px;
    height: 250px;
}
.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em 1em 0em 0em;
}
.card-text {
    padding: 2em;
    font-size: .8rem;
}
.card-text button {
    padding: .5em 2em;
    border: none;
    background-color: var(--mint);
    margin-top: 1em;
    border-radius: .3em;
    cursor: pointer;
}
.card-text a {
    text-decoration: none;
}
.card-text button:hover {
    background-color: mediumseagreen;
}
.card-text h3{ color: var(--purple)}
.duration img, .interval img {width: 1.5em;}
.other-details {
    display: flex; 
    border-top: 1px solid lightgrey; 
    margin-top: 1em; 
    padding-top: 1em;
    gap:.5em
} 

.other-details p {
    font-size: .8rem;
    font-weight: bold;
    color: rgb(70, 69, 69);
}

.interval, .duration {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;;
}


@media only screen and (min-width: 768px) {


    .what-we-do-text h2 {
        padding: 1em 2em;
        font-size: 3rem;
    }
    
    .what-we-do-text p {
        padding: 0em 4.3em;
        font-size: 1.4rem;
        line-height: 3rem;
    }
    .what-we-card-holder {
        margin: 2em 2em;
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 1.5em;
        
     
    }
    

}


@media only screen and (min-width: 1024px) {

    .what-we-card-holder {
        margin: 2em 1em;
        display: grid;
        grid-template-columns: repeat(3, 2fr);
        gap: 2.4em;
        
     
    }


}