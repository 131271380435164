.backend-syllabus-text {
    padding: 2em;
    background-color: darkblue;
}

.backend-syllabus-text h3 {
    color: var(--mint);
}

.backend-syllabus-text p {
    color: #fff;
}

.backend-syllabus-form {
    padding: 2em;
    display: flex;
    flex-direction: column;
}

.backend-syllabus-form 
input[type='text'],
input[type='email'],
input[type='tel']   {
    padding: .7em;
    margin: .6em 0em;
    width: 100%;
    border: 1.2px solid var(--purple);
    border-radius: .4em;
}

.backend-syllabus-form form input:focus {
    outline: none;
}

.backend-terms {
    display: flex;
    justify-content: right;
    align-items: first baseline;
    gap: 1em;
}

.backend-syllabus-form input[type='submit']{
    padding: .7em 2em;
    margin-top: 1.5em;
    border: none;
    background-color: var(--purple);
    color: #fff;
}

.backend-syllabus-form input[type='submit']:hover {
    color: var(--purple);
    background-color: var(--mint);
}



@media only screen and (min-width: 768px) {
    .backend-syllabus-text {
        padding: 3em 5em;
    }
    
    .backend-syllabus-text h3 {
        font-size: 2.2rem;
    }
    
    .backend-syllabus-text p {
        font-size: 1.5rem;
    }


    .backend-syllabus-form {
        padding: 3em 8em;
    }

}

@media only screen and (min-width: 1024px) {
    .backend-syllabus-form {
        padding: 5em 16em;
    }
}
