.business-form-text {
    padding: 2em;
    background-color: darkblue;
}

.business-form-text h2 {
    color: var(--mint)
}

.business-form-text p {
    color: #fff;
    margin-top: 1em;
}

.business-form-text ul li {
    color: var(--mint);
    margin: .5em 2em;
    font-size: .9rem;
}

.business-form-form form {
    padding: 2em;
    display: flex;
    flex-direction: column;
}

.business-form-form p {
    color: darkgray;
    padding: 1em;
}

.business-form-form form select,
.business-form-form form textarea,
.business-form-form form input
 {
    padding: .7em;
    margin: .6em;
    border: 1px solid darkblue;
    width: 100%;
    border-radius: .2em;
}

.business-form-form form input:focus,
.business-form-form form textarea:focus, 
.business-form-form form select:focus {
    outline: none;
}


.business-form-form form input[type='submit'] {
    background-color: var(--mint);
    border: none;
    width: 10em;
    color: darkblue;
}

.business-form-form form input[type='submit']:hover {
    background-color: darkblue;
    color: var(--mint);
}


@media only screen and (min-width: 768px) {
    .business-form-text {
        padding: 4em 5em;
    }
    
    .business-form-text h2 {
       font-size: 2.2rem;
    }
    
    .business-form-text p {
       font-size: 1.5rem;
    }
    
    .business-form-text ul li {
        font-size: 1.1rem
    }
    .business-form-form form {
        padding: 3em 10em;
    }
}

@media only screen and (min-width: 1024px) {

    .business-form-form form {
        padding: 3em 20em;
    }
}


