.footer-container {
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: center;
}

.useful-links {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 3em;
}

.useful-links a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.useful-links a:hover {
    color: var(--mint);
    border-bottom: 1px solid var(--mint);
}
.social-icon {
    display: flex;
    gap: 1em;
    flex-direction: column;
    padding-bottom: 4em;
}

.social-icon img {
    width: 2em;
}


.footer-logo img {
    width: 6em;
    padding-top: 6em;  
}

.copy-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    background-color: var(--purple);
    color: #fff;
    text-align: center;

}

.copy-right p {
    margin: .5em;
    overflow: hidden;
}

.policy-terms {
    display: flex;
    gap: 1em;
}

.policy-terms a {
    color: var(--mint);
}
@media  only screen and (min-width: 768px) {
    .footer-container {
        display: flex;
        background-color: black;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        text-align: center;      
    }

    .useful-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        gap: 1em;
        padding: 3em;
        text-align: center;
    }

    .social-icon {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1em;
    }


    

}