.privacy-policy-container {
    display: flex;
    flex-direction: column;
}

.privacy-policy-container-text {
    padding: 1.7em;
    color: #435461;

}

.privacy-policy-container p {   
    line-height: 1.8em;
}

.privacy-policy-container h1 {
    color: var(--purple)
}

.policy-content {
    color: #435461;
    padding: 0em 1.7em;
    font-size: 1rem;
}

.policy-content p {
    margin: .6em 0em;
}

.policy-content ol {
    margin-left: 1em;
}

.policy-content ol li {
    color: var(--purple);
    padding: 1em 0em;
}


@media only screen and (min-width: 768px) {

    
.privacy-policy-container-text {
    padding: 5em 5em 2em 5em;
}
.policy-content {
    padding: 1em 5em 5em 5em;

}
    
}