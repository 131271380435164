.graphic-design-call-to-action-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(5, 76, 95);
    padding: 2em;
}

.graphic-design-call-to-action-container h2 {
    color: var(--mint);
}

.graphic-design-call-to-action-container p {
    color: #dff;
}


@media only screen and (min-width: 768px) {
    .graphic-design-call-to-action-container {
        padding: 6em;
    }
    
    .graphic-design-call-to-action-container h2 {
        font-size: 2.5rem;
    }
    
    .graphic-design-call-to-action-container p {
        font-size: 1.2rem;
        line-height: 2rem;
    }
    
    
}

