.business-container {
    display: flex;
    flex-direction: column;
}

.business-container-image {
    position: relative;
    height: 670px;
    width: 100%;
    background-color: var(--purple);    
}

.business-container-image img {
    width: 100%;
    height: 100%;
    opacity: .3;
    object-fit: cover;
}

.corporate-call-holder h2 {
    padding: 1em 2em .6em 2em;
}

.business-container-text {
    position: absolute;
    padding: 4em 2em;

}

.business-container-text h1 {
    color: #fff;
    font-size: 1.7rem;
}

.business-container-text p {
    color:#fff;
    margin-top: 1em;
    line-height: 1.8rem;
}

.corporate-call-image {
    padding: 2em;
}

.corporate-call-image h2 {
    margin-top: 2em;;
}

.corporate-call-image img {
    width: 100%;
    object-fit: cover;
}

.corporate-call-text {
    display: flex;
    align-items: center;
    padding: .7em 2em;
    gap: 1em;
}

.corporate-call-text img {
    width: 2em;
}

.final-call {
    display: flex;
    padding: 4em 2em;
    margin-top: 4em;
    flex-direction: column;
    background-color: var(--purple);
}

.final-call img {
    width: 100%;
    margin-bottom: 1em;
    border-radius: .6em;
    
}

.final-call h3 {
    color: var(--mint);
    margin-bottom: 1em;
}

.final-call p {
    color: #fff;
}

.final-call button {
    display: flex;
    padding: .7em 2em;
    border-radius: .4em;
    border: none;
    margin-top: 2em;
    background-color: var(--mint);
    align-self: first baseline;
}

.final-call button a {
    text-decoration: none;
    font-size: 1rem;
    color: var(--purple);
}

.final-call button:hover {
    background-color: #fff;
}

@media only screen and (min-width: 768px) {

    .business-container-text {
        padding: 6em 7em;    
    }

    .business-container-text h1 {
        font-size: 3rem;
    }
    
    .business-container-text p {
        font-size: 1.2rem;
        line-height: 2.1rem;
    }
    .corporate-call-image {
       padding: 2em;
       width: 60%;
       height: 400px;
       border: 3px solid;
       border-radius: 1em;
    }

    .corporate-call-image img {
        width: 100%;
        height: 100%;
        border-radius: 1em;
    }

    .corporate-call {
        display: flex;
        justify-content: center;
        align-items: center ;
        padding: 2em;
    }


    .final-call {
        display: flex;
        flex-direction: row;
        padding: 3em;
        gap: 2em;

    }
    
    .final-call-image {
        width: 50%;
        margin-bottom: 1em;
        border-radius: .6em;
        height: 310px;
        
    }

    .final-call-image img {
        height: 100%;
        object-fit: cover;
    }

    .final-call-text {
        width: 50%;
    }
    

}