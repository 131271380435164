.landing-page-container {
    display: flex;
    flex-direction: column;
}

.landing-page-text {
    padding: 2em;
}

.landing-page-text h1 {
    color: var(--purple);
    font-size: 1.4rem;
}

.landing-page-text p {
    margin-top: .7em;
    color: #38516d;
    line-height: 1.8rem;
}

.landing-page-image {
    width: 100%;
    height: 450px;
    display: none;
}

.landing-page-image img {
    display: none;
    width: 100%;
    object-fit: cover;
}


.landing-page-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;    
    margin: 2em 0em;
}

.landing-page-buttons a {
    text-decoration: none;
}

.landing-page-buttons a.student {
    background-color: var(--purple);
    padding: .4em 2em;
    border-radius: .4em;
    color: #fff;
    cursor: pointer;
}

.landing-page-buttons a.business {
    border: 2px solid var(--purple);
    padding: .4em 2em;
    border-radius: .4em;
    color: var(--purple);
    cursor: pointer;
}
.landing-page-buttons a.student:hover {
    background-color: var(--mint);
    color: #fff;
}

.landing-page-buttons a.business:hover {
    background-color: var(--purple);
    color: #fff;    
}


@media only screen and (min-width: 768px) {

    .landing-page-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
    }

    .landing-page-text {
        padding: 2em 0em 2em 2em;
        width: 60%;
        height: 100%;
        margin: 0em 0em 0em 2em;
    }

    .landing-page-image {
 
        display: block;
        width: 40%;
        height: 100%;
        margin: 1em 2em 2em 0em;
    }

    .landing-page-image img {
        display: block;
        object-fit: cover;
    }

    .landing-page-buttons {
        display: flex;

    }

    .landing-page-buttons a.student {
        padding: .4em 1em;
    }
    
    .landing-page-buttons a.business {
        padding: .4em 1em;
    }
}

@media only screen and (min-width: 1024px) {


    .landing-page-text h1 {
        color: var(--purple);
        font-size: 2.6rem;
    }
    
    .landing-page-text p {
        margin-top: .7em;
        color: #38516d;
        line-height: 2rem;
        font-size: 1.2rem;
    }
    
}