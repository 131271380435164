.calendar-container {
    display: flex;
    flex-direction: column;
}

.calendar-text {
    padding: 4em;
    background-color: var(--mint);
    text-align: center;
}

.calendar-card {
    display: flex;
    flex-direction: column;
    padding: 2em;
    border: 1px solid var(--purple);
    margin: 2em;
    border-radius: .4em;
    text-align: center;
}

.calendar-card h3 {
    color: var(--purple);
    margin-bottom: .2em;
}


@media only screen and (min-width: 768px) {


    .calender-card-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        padding: 2em;
    }
   
}