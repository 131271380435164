.online-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

@media only screen and (min-width: 768px) {

    .online-container h1 {
        font-size: 3rem;
    }
    
}