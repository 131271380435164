.nav-container {
    display: flex;
    width: 100%;
    background-color: #fff;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    box-shadow:  0 4px 6px -1px rgb(0 0 0 / 0.1);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
}

.logo {
    padding: 2em 0em 1em 2em;
}

.logo img {
    width: 10em;
    cursor: pointer;
}
 
.social-icons img {
    width:1.7em;
    cursor: pointer;
    margin: .1em;
    
}
.hamburger {
    padding: 1.35em 2em 0em 0em;
}
.hamburger img {
    width: 2em;
    cursor: pointer;
}
.close {
    width: 2em;
    cursor: pointer;
}

.mobile-view a {
    text-decoration: none;
    padding: .5em;
    color: var(--purple);
    display: flex;
    flex-direction: column;
}

.mobile-view a.browse {
    background-color: var(--purple);
    padding: .6em;
    border-radius: .3em;
    color: #fff;
}


.mobile-view a:hover{
    color:var(--mint);
}

.mobile-view a.browse:hover {
    color: var(--magenta);
}

.mobile-view {
    color: var(--purple);
    width: 100%;
    padding: 2em;
    height: 100vh;
}

.desktop-view {
    display: none;
}

@media (min-width: 798px) {

    .hamburger img {
        display: none;
    }
    .close {
        display: none;
    }
    
    .desktop-view {
        display: flex;
        gap: 1em;
        justify-content: center;
        align-items: center;
        padding: 1.3em 2em 0em 0em;
    }
    
    .desktop-view a {
        text-decoration: none;
        color: rgb(87, 85, 85);
        display: flex;
        gap: 1em;
    }

    .desktop-view a.browse {
        background-color: var(--purple);
        padding: .64em;
        border-radius: .2em;
        color: #fff;
    }

  .desktop-view a.active-link {
       color: var(--purple);
    }

    .desktop-view a.browse:hover {
        color: var(--magenta)
    }

    .desktop-view a:hover {
        color: var(--purple);
        border-bottom: 2px solid var(--gold);      
    }


    
    
    
  }