.terms-page-container {
    display: flex;
    flex-direction: column;
}

.terms-page-text {
    padding: 1.7em 1.7em 0em 1.7em;
    color: var(--purple)
}

.terms-contents {
    padding: 1.7em;
    line-height: 1.7rem;
}

.terms-contents span {
    font-weight: bold;
}

.terms-contents ul li {
    margin-left: 1em;
    color: #435461;
}

.terms-contents ol li {
    margin-left: 1em;
    color: #435461;
}


.terms-contents h3 {
    color: var(--purple);
    padding: .7em 0em 0em 0em;
}

.terms-contents p {
    color: #435461;
    padding: .7em 0em;
}

@media only screen and (min-width: 768px) {

    .terms-page-text {
        padding: 5em 5em 2em 5em;
    }
    
    .terms-contents {
        padding: 1em 5em 5em 5em;
    }
    

}