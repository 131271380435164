.grow-container {
    display: flex;
    flex-direction: column;
}

.grow-text {
    margin-top: 3em;
    padding: 2em;
    color:rgb(39, 3, 72);
    text-align: center;
}

.grow-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em 2em;
    align-items: center;
    box-shadow:  0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.grow-card-text {
    padding: 2em;
}


.grow-card-image {
    margin: 1em;
    width: 100%;
    height: 300px;
}

.grow-card-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 2em;
}


@media only screen and (min-width: 768px) {

    .grow-text h2{
    padding: 3em 3em 0em 3em;
    font-size: 2rem;       
    }

    .grow-cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
 
    }

    .grow-card-text {
        padding: 2em;
        line-height: 2em;
    }    
}

@media only screen and (min-width: 1024px) {

    .grow-cards {
        display: flex;
        flex-direction: column;
     
    }
    .grow-card-holder {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
    }

}