 .testimonial-container {
    padding: 2em;
    display: flex;
    flex-direction: column;
 }
 .testimonial-container p {
   text-align: center;
   font-size: 1.3rem;
   padding: 2em;
 } 

 .numbers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
 }

 .numbers h2 {
    color: var(--purple);
    font-size: 4rem;
 }

 .numbers h6 {
    color: gray;
    font-size: 1rem;
 }

 .our-students {
    margin-top: 4em;;
 }

 .our-students h2{
    color: var(--purple);
    padding-bottom: 1em;
 }

 .our-students p {
    padding: 2em;
    border: .7px solid var(--purple);
    border-radius: .5em;
    margin-bottom: 1em;
    font-size: 1rem;
 }


 @media only screen and (min-width: 768px) {

   .numbers {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 3em;
   }
   .our-students h2{
      padding: 3em;
      text-align: center;
   }

   .our-student-card {
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 1.4em;
   }
  
 }