.why-us-container {
    background-color: rgb(217, 255, 236);

}

.why-us-text {
    padding: 2em;
}

.why-us-text h2 {
    color: var(--purple)
}

.why-us-section {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.why-us-image {
    margin:1em;
    border-radius: 1em;
}

.heading {    
    padding: .8em .5em;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1em;
    margin: 0em 1em;
    border-bottom: 1px solid lightgray;
    background-color: white;
    color: var(--purple);
}

.heading img {
    width: 2em;
    background-color:var(--purple);
    padding: .4em;
}

.angle-arrow img {
    width: 2em;
    background-color: transparent;
    padding: 0;
}

.descriptions  p {
    padding: 1em 2em;
    line-height: 2em;
    background-color: #fff;
    margin: 0em 1em;
}

@media only screen and (min-width: 768px) { 

    .why-us-text {
        padding: 3em;
        font-size: 1.3rem;
    }
    .why-us-section {
        display: flex;
        flex-direction: row;

    }
    
    .why-us-image {
        width: 50%;
        border-radius: 1em;
    }

    

    
} 