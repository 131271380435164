@font-face {
  font-family: myFont ;
  src: url('./Fonts/POPPINS-REGULAR.TTF');
} 


:root {
  --magenta: #FF00FF;
  --purple: #6D17FF;
  --mint: #99FF99;
}

* {
  font-family: myFont;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

