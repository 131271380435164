.product-design-apply-page-container {
    display: flex;
    flex-direction: column;
}

.product-design-apply-text {
    padding: 2em;
    background-color: darkblue;
}
.product-design-apply-text h2 {
    color: var(--mint)
}
.product-design-apply-text p {
    color:#fff;
    line-height: 1.8rem;
}

.product-design-apply-form {
    padding: 2em;
    display: flex;
    flex-direction: column;
    color:  #435461;
}

.product-design-apply-form h4 {
    margin-bottom: 1em;
}


.first-section {
    display: flex;
    flex-direction: column;
}

.first-section p {
    padding: 0;
    margin: .7em 0em;
}

.first-section input {
    padding: .8em;
}


.second-section {
    display: flex;
    flex-direction: column;
}

.second-section p {
    margin-top: 2em;
    padding-bottom: .4em;
    font-weight: bold;
}

.second-section label, .third-section label {
    cursor: pointer;
}


.third-section p {
    margin-top: 2em;
    padding-bottom: .4em;
    font-weight: bold;

}

.form-submit {
    margin-top: 3em;
    padding: 1em 2.5em;
    border: none;
    background-color: var(--purple);
    color: #fff;
}

.form-submit:hover {
    background-color: var(--mint);
    color: var(--purple)
}



@media only screen and (min-width: 768px) {

    .product-design-apply-text {
        padding: 3em;
    }
    .product-design-apply-text h2 {
        font-size: 2.5rem;
    }
    .product-design-apply-text p {
        font-size: 1.4rem;
        line-height: 2em;
    }
    .product-design-apply-text {
        padding:4em;
    }
    .product-design-apply-text h2 {
        font-size: 2.5rem;
    }
    .product-design-apply-text p {
        font-size: 1.4rem;
        line-height: 2em;
    }
    
    .product-design-apply-form {
        padding: 4em 8em;
    }

}


@media only screen and (min-width:1024px) {
    .product-design-apply-form {
        padding: 6em 16em;
    }

}