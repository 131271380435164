.faq-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 3em;
    background-color:  rgb(246, 244, 246);
}

.faq-text {
    padding: 2em;
    color:  var(--purple);
}

.questions img {
    width: 2em; 
}

.questions {
    display: flex;
    align-items: center;
    padding: .5em .5em;
    color: var(--purple)   ;
    margin: .0em 1em;
    border-bottom: .2px solid rgb(228, 202, 252);
    border-top: .2px solid rgb(228, 202, 252);
}

.active-question {
    color: lightcyan;
    background-color: var(--purple)
}
.response {
    background-color: #fff;
    margin: 0em 1em;;
    color: rgb(59, 84, 118);    
}

.response p {
    padding: 1em;
}


@media only screen and (min-width: 768px) {


.faq-text {
    max-width: 670px;
    margin: 0 auto;
}

.questions {
    max-width: 670px;
    margin: 0 auto;
}
.response {
    max-width: 670px;
    margin: 0 auto;   
    line-height: 2rem; 
}

}