.contact-container {
    display: flex;
    flex-direction: column;
}

.contact-text {
    padding: 2em;
}

.contact-text h2 {
    margin-top: .6em;
    color: var(--purple);
    font-size: 3rem;
    text-transform: uppercase;
}

.contact-text h5 {
    margin: 1em 0em;
    color: var(--purple)
}