.about-us-container {
    display: flex;
    flex-direction: column; 
}
.about-us-image {
    width: 100%;
    height: 560px;
    position: relative;
    background-color: darkblue;
}

.about-us-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .3;
}
.about-us-text {
    position: absolute;
    padding: 2em;
}

.about-us-text h1 {
    color: var(--mint);
    margin-bottom: .6em;
}

.about-us-text p {
    color: #fff;
    line-height: 1.7em;
}

.who-we-are {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.who-we-are-image img {
    width: 100%;
    border-radius: 1em;
}

.who-we-are-text h2 {
    color: var(--purple);
    margin: 1em 0em;
}

.who-we-are-text p {
    margin: 1em 0em;
    font-size: .9em;
    color: #435461;
    line-height: 1.7rem;
}
.mission h3 {
    color: var(--purple)

}

@media only screen and (min-width: 768px) {
    .about-us-text {
        padding: 3em 4em 4em 4em;
    }
    .about-us-text h1 {
        font-size: 4rem;
        padding-bottom: 0em;
    }
    
    .about-us-text p {
        font-size: 1.5rem;
    }
    
    .who-we-are {
        flex-direction: row;
        padding: 4em;
        justify-content: center;
        align-items: center;
        gap: 2em;
        
    }

    .who-we-are-image {
        width: 50%;
        padding: 0em;
    }

    .who-we-are-text {
        width: 50%;
        padding: 0em;
    }
    
    
}

@media only screen and (min-width: 1024px) {
    .about-us-text {
        padding: 3em 6em 6em 6em;
    }

    .about-us-text p {
        line-height: 3rem;
    }

    .who-we-are-text h2 {
        font-size: 2rem;
    }

    .who-we-are-text p {
        font-size: 1.3rem;
        line-height: 2.2rem;
    }
    
}

